/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.5 circles.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Circles(props) {
  const { nodes, materials } = useGLTF('models/circles.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Curve001.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve002.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve003.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve004.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve005.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve006.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve007.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve008.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve009.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve010.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve011.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve012.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve013.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve014.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve015.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve016.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve017.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve018.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve019.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve020.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve021.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve022.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve023.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve024.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve025.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve026.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve027.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve028.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve029.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
      <mesh geometry={nodes.Curve030.geometry} material={materials['SVGMat.001']} position={[-0.136, 0, 0.135]} />
    </group>
  )
}

useGLTF.preload('/circles.glb')
