/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.5 grotto_cavern_cave.glb
Author: pixol3d (https://sketchfab.com/pixol3d)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/grotto-cavern-cave-aa059550ba7543b9b5eb37d7fc19363d
Title: Grotto Cavern Cave
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Cavern(props) {
  const { nodes, materials } = useGLTF('models/grotto_cavern_cave.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Object_2.geometry} material={materials.defaultMat} rotation={[-Math.PI / 2, 0, 0]} scale={1.439} />
    </group>
  )
}

useGLTF.preload('/grotto_cavern_cave.glb')
