/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.5 pillar.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function PillarModel(props) {
  const { nodes, materials } = useGLTF('/models/pillar-transformed.glb')

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.group1963348611.geometry} material={materials.mat15} />
    </group>
  )
}

useGLTF.preload('/pillar-transformed.glb')
